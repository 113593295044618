import React from "react"
import IndexPage from "../templates/index-page"
import { graphql } from "gatsby"

const Index = ({
  data: {
    markdownRemark: { frontmatter },
    allMarkdownRemark: { edges },
  },
}) => {
  return <IndexPage {...frontmatter} edges={edges} />
}

export const indexDePageQuery = graphql`
  query indexEnPage {
    markdownRemark(fields: { slug: { eq: "/" }, langKey: { eq: "en" } }) {
      frontmatter {
        description
        title
        hero {
          title
          description
          buttonLink
          buttonText
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                aspectRatio: 2
              )
            }
          }
        }
        intro {
          title
          description
        }
        whatWeDo {
          title
          description
          buttonLink
          buttonText
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, height: 300)
            }
          }
        }
        testimonials {
          author
          authorInfo
          content
        }
        newsIntroMD
      }
    }
    allMarkdownRemark(
      filter: {
        fields: {
          slug: { regex: "//news/[a-z0-9_-]+/?/" }
          langKey: { eq: "en" }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            title
            author {
              name
            }
            date
          }
          excerpt(pruneLength: 200)
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Index
